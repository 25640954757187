import countVCTElement from './countVCTElement';

export default function getVCTScript(imageUrl) {
  if (!imageUrl) {
    return '';
  }
  // non minified vctScript:
  /*
  (function() {
    ${countVCTElement};
    var image = new Image();
    image.onload = window.markVisuallyComplete;
    image.srcset = `${srcSet || imageUrl}`;
    image.src = `${imageUrl}`;
  })()
  */
  /* eslint-disable-next-line max-len */
  return `(function(){${countVCTElement};var i=new Image();i.onload=window.markVisuallyComplete;i.src="${imageUrl}";})()`;
}
